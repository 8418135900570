import axios from 'axios';
import loginHeader from './login-header';
import firebase from 'firebase';

const API_URL = process.env.VUE_APP_FPLAN_WEB_ENDPOINT + 'auth/';

class AuthService {
  async login(user) {
          console.log('spoo')

    const response = await this.apiLogin(user)
    console.log(response)
    try {
      const response2 = await this.wpLogin(user, response.nonce)
      console.log(response2)
      await firebase.auth().signInWithCustomToken(response.fbToken)
      return response
    } catch {
      return response
    }
  }
  apiLogin(user) {
    const uninterceptedAxiosInstance = axios.create();

    return uninterceptedAxiosInstance.get(API_URL + 'login', {
        headers: loginHeader(user)
      }).then(response => {
        if (response.data.token) {
          var str = JSON.stringify(response.data);
          self.$cookies.set("user", str, 12 * 60 * 60)
        }
        return response.data
      });
  }
  /*
  login(user) {
    return axios.all([ this.apiLogin(user), this.wpLogin(user)]).then(axios.spread((data1, data2) => {
      return firebase.auth().signInWithCustomToken(data1.fbToken)
        .then((userCredential) => {
          // Signed in
          console.log(data1)
          return data1
          // ...
          })
          .catch((error) => {
            var errorCode = error.code;
            var errorMessage = error.message;
            // ...
            console.log(data1)
            return data1
          });
      
    }
    ))
  }
  */
  logout() {
    self.$cookies.remove("user");
  }

  wpLogin(user, nonce) {
    let userId = {
      "username": user.email,
      "password": user.password,
      "woocommerce-login-nonce": nonce,
      "login": "Log In",
      "_wp_http_referer": "/my-account/"
    }
    const qs = require('qs');
    const options = {
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: qs.stringify(userId),
      url: API_URL + 'my-account',
    };
    return axios(options).then(
      response => {
        console.log(response)
        if (response.data.token) {
          var str = JSON.stringify(response.data);
          self.$cookies.set("user", str, 12 * 60 * 60)
        }
        return response.data;
      }, error => {
        console.log(error)
      });
  }
}

export default new AuthService();