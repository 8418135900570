
<template>
  <component :is="layout">
    <keep-alive include="Maps, Tracklogs, GroupTracklogs">
      <router-view :key='$route.fullPath' :layout.sync="layout"/>
    </keep-alive>
  </component>
</template>

<script>
export default {
  name: `App`,
  data() {
    return {
      layout: `div`,
    };
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
html {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  position: fixed;
  overflow: hidden;
}
body {
  margin: 0 !important;
  padding: 0;
  height: 100%;
  max-height: 100%;
  overscroll-behavior: none;
  overflow: hidden;
  -ms-overflow-style: none;
}
</style>
