export const maps = {
  namespaced: true,
  state: {
    mapStyle: 'mapbox://styles/bevan/cjugtgps34snq1fqiuilp9v88',
    zoom: 3.5,
    vfrVisible: false,
    centre: {
      df: '-28',
      ff: '135'
    },
    rasterTiles: '',
    weatherTiles: '',
    radarOn: false,
    irOn: false,
    lightningOn: false,
    trafficOn: false,
    pointsOn: false,
    sigmetOn: false,
    targetCount: 0,
    overlayUrl: '',
  },
  mutations: {
    changeMapStyle (state, style) {
      state.mapStyle = style;
    },
    changeRasterTiles (state, style) {
      state.rasterTiles = style;
    },
    changeWeatherTiles (state, style) {
      state.weatherTiles = style;
    },
    changeMapZoom (state, zoom) {
      state.zoom = zoom;
    },
    changeToVFR (state) {
      state.vfrVisible = true;
    },
    changeMapLatitude (state, latitude) {
      state.df = latitude;
    },
    changeMapLongitude (state, longitude) {
      state.ff = longitude;
    },
    toggleRadarOn(state, on) {
      state.irOn = false
      state.radarOn = on
    },
    toggleIROn(state, on) {
      state.radarOn = false
      state.irOn = on
    },
    toggleTraffic(state, on) {
      state.trafficOn = on
    },
    togglePoints(state, on) {
      state.pointsOn = on
    },
    toggleLightning(state, on) {
      state.lightningOn = on
    },
    toggleSIGMET(state, on) {
      state.sigmetOn = on
    },
    updateMapOverlay(state, url) {
      state.overlayUrl = url
    }
  }
};