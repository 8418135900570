import Vue from 'vue';
import Vuex from 'vuex';

import { auth } from './modules/auth';
import { maps } from './modules/maps';
import { logs } from './modules/logs';
import { plans } from './modules/plans';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    maps, 
    logs,
    plans
  }
});
