import axios from 'axios';
import authHeader from './auth-header';
import AuthService from '../services/auth.service';


const API_URL = process.env.VUE_APP_FPLAN_WEB_ENDPOINT + 'api/';


class UserAPIService {
  constructor() {
    this.configure();
  }

  configure() {
    axios.interceptors.response.use(function (response) {
      return response
    }, function (error) {
      console.log(error.response.data)
      if (error.response.status === 401) {
        console.log('got 401 - logout user')
        AuthService.logout();
        window.location.href = "login";
        window.Intercom("shutdown")
      }
      return Promise.reject(error)
    })
  }
  getUserFlights(page) {
    return axios.get(API_URL + 'v4/flights?per=20&page=' + page, { headers: authHeader() });
  }

  archiveFlight(trackId) {
    return axios.delete(API_URL + 'v4/flight/' + trackId, { headers: authHeader() });
  }

  getUserDevices() {
    return axios.get(API_URL + 'v4/user/devices', { headers: authHeader() }).catch(error => console.log(error));
  }

  signOutDevice(deviceId) {
    return axios.get(API_URL + 'v4/user/devices/' + deviceId + '/signout', { headers: authHeader() }).catch(error => console.log(error));
  }

  getUserShareToken() {
    return axios.get(API_URL + 'v4/user/share', { headers: authHeader() }).catch(error => console.log(error)); 
  }
  
  getUserExistingShareToken() {
    return axios.get(API_URL + 'v4/user/shared', { headers: authHeader() }); 
  }

  deleteUserShareToken(token) {
    return axios.delete(API_URL + 'v4/user/share/' + token, { headers: authHeader() }); 
  }

  getUserGroups() {
    return axios.get(API_URL + 'v4/groups', { headers: authHeader() }); 
  }

  getGroup(groupId) {
    return axios.get(API_URL + 'v4/group/' + groupId, { headers: authHeader() }); 
  }
  
  getFlightLogs() {
    return axios.get(API_URL + 'v4/points?hoursago=8', { headers: authHeader() });
  }
  getUsers(email) {
    return axios.get(API_URL + 'v4/users?email=' + email, { headers: authHeader() }).catch(error => console.log(error)); 
  }
  addUserToGroup(groupId, user) {
    return axios.post(API_URL + 'v4/group/' + groupId, user, { headers: authHeader() }); 
  }
  addNewUser(user) {
    return axios.post(API_URL + 'v4/users/create', user, { headers: authHeader() }); 
  }
  // notice the weirdness to put a body in a delete request...
  //
  deleteGroupUser(groupId, user) {
    return axios.delete(API_URL + 'v4/group/' + groupId, { data: user, headers: authHeader() }); 
  }
  changeGroupUser(groupId, user) {
    return axios.patch(API_URL + 'v4/group/' + groupId, user, { headers: authHeader() }); 
  }
  changePasswordGroupUser(groupId, password) {
    return axios.post(API_URL + 'v4/group/' + groupId + "/password", password, { headers: authHeader() }); 
  }
  addNewGroupUser(groupId, user) {
    return axios.post(API_URL + 'v4/group/' + groupId + "/user", user, { headers: authHeader() }); 
  }
  getGroupDevices(groupId) {
    return axios.get(API_URL + 'v4/group/' + groupId + "/devices", { headers: authHeader() }); 
  }
  getGroupPlates(groupId) {
    return axios.get(API_URL + 'v4/group/' + groupId + "/plates", { headers: authHeader() }); 
  }
  deleteGroupPlate(groupId, plateId) {
    return axios.delete(API_URL + 'v4/group/' + groupId + "/plates/" + plateId, { headers: authHeader() }); 
  }
  addGroupPlate(groupId, plate) {
    return axios.post(API_URL + 'v4/group/' + groupId + "/plates", plate, { headers: authHeader() }); 
  }
  
  getGroupOverlays(groupId) {
    return axios.get(API_URL + 'v4/group/' + groupId + "/kml", { headers: authHeader() }); 
  }
  deleteGroupOverlay(groupId, plateId) {
    return axios.delete(API_URL + 'v4/group/' + groupId + "/kml/" + plateId, { headers: authHeader() }); 
  }
  addGroupOverlay(groupId, plate) {
    return axios.post(API_URL + 'v4/group/' + groupId + "/kml", plate, { headers: authHeader() }); 
  }

  getGroupSubs(groupId) {
    return axios.get(API_URL + 'v4/group/' + groupId + "/subscriptions", { headers: authHeader() }); 
  }

  getFlightPlan(planId) {
    return axios.get(API_URL + 'v4/fpl/' + planId, { headers: authHeader() }); 
  }

  getCallsigns() {
    return axios.get(API_URL + 'v4/ac', { headers: authHeader() }); 
  }
  redeemVoucher(voucher) {
    return axios.post(API_URL + 'v4/users/redeem', voucher, { headers: authHeader() }); 
  }
  userSubscriptions() {
    return axios.get(API_URL + 'v4/users/subscriptions', { headers: authHeader() }); 

  }
  userCards() {
    return axios.get(API_URL + 'v4/users/cards', { headers: authHeader() }); 
  }
  deleteCard(token) {
    return axios.delete(API_URL + 'v4/users/cards/' + token, { headers: authHeader() }); 

  }
  addCard(card) {
    return axios.post(API_URL + 'v4/users/cards', card, { headers: authHeader() }); 
  }
  chargeCard(orderId) {
    return axios.get(API_URL + 'v4/users/charge/' + orderId, { headers: authHeader() }); 
  }
  getSMS() {
    return axios.get(API_URL + 'v4/users/sms', { headers: authHeader() }); 
  }
  addSMS(number) {
    return axios.post(API_URL + 'v4/users/sms', number, { headers: authHeader() }); 
  }
  deleteSMS(id) {
    return axios.delete(API_URL + 'v4/users/sms/' + id, { headers: authHeader() }); 
  }
  updateSubscription(subId, update) {
    return axios.patch(API_URL + 'v4/users/subscriptions/' + subId, update, { headers: authHeader() }); 
  }
  adminSubscriptions(userId) {
    return axios.get(API_URL + 'v4/users/' + userId + '/subscriptions', { headers: authHeader() }); 
  }
  adminUpdateSubscriptions(userId, subId, update) {
    return axios.patch(API_URL + 'v4/users/' + userId + '/subscriptions/' + subId, update, { headers: authHeader() }); 
  }
  adminCards(userId) {
    return axios.get(API_URL + 'v4/users/' + userId + '/cards', { headers: authHeader() }); 
  }
  adminAddCard(userId, card) {
    return axios.post(API_URL + 'v4/users/' + userId + '/cards', card, { headers: authHeader() }); 
  }
  adminDeleteCard(userId, token) {
    return axios.delete(API_URL + 'v4/users/'+ userId + '/cards/' + token, { headers: authHeader() }); 
  }
}

export default new UserAPIService();