export default function authHeader() {

    let user = self.$cookies.get("user");

    if (user && user.token) {
        return { Authorization: 'Bearer ' + user.token };
    }
    else {
        return { };
    }
}