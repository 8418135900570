import Vue from 'vue';

import App from './App.vue';

import Vuex from 'vuex';

import * as VeeValidate from 'vee-validate';

import VueCookies from 'vue-cookies';

import VueMoment from 'vue-moment';
import moment from 'moment-timezone';

import router from './router';
import store from './store';
import axios from 'axios';

import { firestorePlugin } from 'vuefire';
import {  BootstrapVue } from 'bootstrap-vue';

import './styles/custom.scss';

import VueSocialSharing from 'vue-social-sharing';

import VueMeta from 'vue-meta';

import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import { BootstrapVueIcons } from 'bootstrap-vue'
import VueMobileDetection from 'vue-mobile-detection'

import VueGtag from 'vue-gtag'

import UserAPIService from './services/user.api.service';

import {
  faRoute,
  faMap,
  faAngleRight,
  faPlane,
  faCloudShowersHeavy,
  faBook,
  faHome,
  faCogs,
  faSignOutAlt,
  faLifeRing,
  faPrint,
  faFileSignature,
  faLocationArrow,
  faChartArea,
  faFolder,
  faRedo,
  faFighterJet,
  faFlag,
  faUpload,
  faSearch,
  faLayerGroup,
  faLock,
  faLockOpen,
  faSun,
  faStopwatch,
  faFileImage,
  faAngleLeft,
  faAngleDown,
  faMountain,
  faCompass,
  faTachometerAlt,
  faAlignCenter,
  faCrosshairs,
  faBookmark,
  faList,
  faPlaneDeparture,
  faShare,
  faTrash,
  faSpinner,
  faUser,
  faUsersCog,
  faUsers,
  faSearchLocation,
  faCalendarAlt
} from '@fortawesome/free-solid-svg-icons';


library.add(
  faRoute, 
  faMap, 
  faAngleLeft,
  faAngleRight, 
  faAngleDown,
  faPlane, 
  faCloudShowersHeavy, 
  faBook, 
  faCogs,
  faHome,
  faSignOutAlt,
  faLifeRing,
  faPrint,
  faFileSignature,
  faLocationArrow,
  faChartArea,
  faFolder,
  faRedo,
  faFighterJet,
  faFlag,
  faUpload, 
  faSearch, 
  faLayerGroup,
  faLock,
  faLockOpen,
  faSun,
  faStopwatch,
  faFileImage,
  faMountain,
  faCompass,
  faTachometerAlt,
  faAlignCenter,
  faCrosshairs,
  faBookmark,
  faList,
  faPlaneDeparture,
  faShare,
  faTrash,
  faSpinner,
  faUser,
  faUsersCog,
  faUsers,
  faSearchLocation,
  faCompass,
  faCalendarAlt
);

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"

moment.tz.setDefault('Etc/UTC');

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons)

Vue.use(Vuex);
Vue.use(VeeValidate);
// this causes a weird 'undefined' on input forms
//Vue.use(VeeValidate, {
//  inject: true,
//  fieldsBagName: 'veeFields'
//});
Vue.use(firestorePlugin);
Vue.use(VueCookies)
Vue.use(VueMoment, { moment });

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})

Vue.use(VueSweetalert2);
Vue.use(VueSocialSharing);

Vue.use(VueMobileDetection)

//Vue.use(VueAnalytics, {
//  id: 'G-N7G80MTWHT',
//  router
//});

Vue.use(VueGtag, {
  config: {
      id: 'G-N7G80MTWHT',
      params: {
          send_page_view: false
      }
  }
}, router)

Vue.$cookies.config('12h','','', null, "Strict")

Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.component('v-select', vSelect)

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app');


